@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body,
html,
#root {
  height: 100%;
  font-family: "Poppins";
}

.topViewMain {
  background-image: url("./img/refer_bg.png");
}

.gradientTxt {
  background-image: linear-gradient(to right, #045de9, #09c6f9);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
